import { ref, onMounted } from "vue";

export const useUiVersion = () => {
  const uiVersion = ref(null);

  const init = () => {
    uiVersion.value = window.uiVersion;
  };

  onMounted(init);

  return {
    uiVersion,
  };
};

export default useUiVersion;