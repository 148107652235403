import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-10 col-md-7 col-lg-4 d-flex align-items-stretch" }
const _hoisted_2 = { class: "card blog__item overflow-hidden" }
const _hoisted_3 = { class: "card-image" }
const _hoisted_4 = ["href"]
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "card-body" }
const _hoisted_7 = { class: "row align-items-center mb-4" }
const _hoisted_8 = { class: "col-12" }
const _hoisted_9 = ["href"]
const _hoisted_10 = { class: "col-12" }
const _hoisted_11 = { class: "text-muted" }
const _hoisted_12 = { class: "card-info" }
const _hoisted_13 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("a", {
          href: $props.guid,
          target: "_blank"
        }, [
          _createElementVNode("img", {
            class: "img-fluid",
            src: $props.featuredImageUrl
          }, null, 8, _hoisted_5)
        ], 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("a", { href: $props.guid }, [
              _createElementVNode("h5", null, _toDisplayString($props.title), 1)
            ], 8, _hoisted_9)
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("span", _hoisted_11, _toDisplayString($props.formattedDate), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("p", null, _toDisplayString($props.formattedDescription), 1)
        ]),
        _createElementVNode("a", {
          href: $props.guid,
          target: "_blank",
          class: "text-secondary"
        }, "Read More", 8, _hoisted_13)
      ])
    ])
  ]))
}