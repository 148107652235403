import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-md-4 article-snippet" }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { style: {"margin-top":"1em"} }
const _hoisted_5 = { class: "snippet" }
const _hoisted_6 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      href: $props.guid,
      target: "_blank"
    }, [
      _createElementVNode("img", {
        class: "img-fluid",
        src: $props.featuredImageUrl
      }, null, 8, _hoisted_3)
    ], 8, _hoisted_2),
    _createElementVNode("h3", _hoisted_4, _toDisplayString($props.title), 1),
    _createElementVNode("p", _hoisted_5, _toDisplayString($props.formattedDescription), 1),
    _createElementVNode("p", null, [
      _createElementVNode("a", {
        class: "readmore",
        href: $props.guid
      }, "Read More", 8, _hoisted_6)
    ])
  ]))
}