import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PostV3 = _resolveComponent("PostV3")
  const _component_PostV2 = _resolveComponent("PostV2")

  return ($setup.uiVersion == 3)
    ? (_openBlock(), _createBlock(_component_PostV3, {
        key: 0,
        guid: $props.post.guid,
        title: $setup.formattedTitle,
        featuredImageUrl: $setup.featuredImageUrl,
        formattedDate: $setup.formattedDate,
        formattedDescription: $setup.formattedDescription
      }, null, 8, ["guid", "title", "featuredImageUrl", "formattedDate", "formattedDescription"]))
    : ($setup.uiVersion == 2)
      ? (_openBlock(), _createBlock(_component_PostV2, {
          key: 1,
          guid: $props.post.guid,
          title: $setup.formattedTitle,
          featuredImageUrl: $setup.featuredImageUrl,
          formattedDate: $setup.formattedDate,
          formattedDescription: $setup.formattedDescription
        }, null, 8, ["guid", "title", "featuredImageUrl", "formattedDate", "formattedDescription"]))
      : _createCommentVNode("", true)
}