import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mobile-scroller"
}
const _hoisted_2 = { class: "mobile-scroller__wrapper" }
const _hoisted_3 = { class: "row flex-nowrap flex-lg-wrap blog__row" }
const _hoisted_4 = {
  key: 1,
  class: "row blogposts"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BlogPost = _resolveComponent("BlogPost")

  return ($setup.uiVersion == 3)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.blogPosts, (blogPost) => {
              return (_openBlock(), _createBlock(_component_BlogPost, {
                post: blogPost,
                key: blogPost.guid
              }, null, 8, ["post"]))
            }), 128))
          ])
        ])
      ]))
    : ($setup.uiVersion == 2)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.blogPosts, (blogPost) => {
            return (_openBlock(), _createBlock(_component_BlogPost, {
              post: blogPost,
              key: blogPost.guid
            }, null, 8, ["post"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
}